import React from "react";
import LandingHeader from "./landingHeader";

const Landing = () => {
    return(
        <>
        <LandingHeader />
        <h1>Coming soon...</h1>
        </>
    )
}

export default Landing;