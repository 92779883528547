import React, { useState, useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from "../firebase";
//import { docRef, collection, addDoc } from "@firebase/firestore";
import "./login.css";
import Navbar from "./header";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";

const Album = () => {
  const [progress, setProgress] = useState(0);
  const [albums, setAlbums] = useState([]);

  const [front_image, setFront_image] = useState("");
  const [cover_image, setCover_image] = useState("");
  const [name, setName] = useState("");

  const navigate = useNavigate();
  const data = {
    name: name,
    front_image: front_image,
    cover_image: cover_image,
  };

  const deleteAlbum = (id) => {
    axios.delete(`https://atfal-backend.herokuapp.com/album/${id}`).then((res)=>{
      alert("The album has been deleted!");
    }).catch(err => {
      alert("Failed to delete :(");
    })
  }

  useEffect(() => {
    const fetchAlbums = () => {
      axios.get("https://atfal-backend.herokuapp.com/album").then((res) => {
        console.log("Response: ", res.data);
        if (res.data !== null) {
          setAlbums(res.data);
        }
      });
    };
    if (albums.length === 0) fetchAlbums();
  }, [albums]);

  const submit = () => {
    axios.post("https://atfal-backend.herokuapp.com/album", data).then(
      (res) => {
        alert("Album created Succesfully!");
      },
      (err) => {
        alert("Faied to create Album");
      }
    );
    console.log("Post Data: ", data);
  };

  const formatFrontHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadFront(file);
  };
  const formatCoverHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadCover(file);
  };
  const uploadFront = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/album_front_images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log("Download URL: ", url);
          setFront_image(url);
          // try {
          //   addDoc(collection(db, "photos"), {
          //     type: "image",
          //     imageURL: url,
          //   });
          //   console.log("Document written with ID: ", docRef.id);
          // } catch (e) {
          //   console.error("Error adding document: ", e);
          // }
        });
      }
    );
  };
  const uploadCover = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/album_cover_images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log("Download URL: ", url);
          setCover_image(url);
          // try {
          //   addDoc(collection(db, "photos"), {
          //     type: "image",
          //     imageURL: url,
          //   });
          //   console.log("Document written with ID: ", docRef.id);
          // } catch (e) {
          //   console.error("Error adding document: ", e);
          // }
        });
      }
    );
  };
  return (
    <div>
      <Navbar />
      <h1>Atfal App: Upload Albums</h1>
      <form onSubmit={formatFrontHandler}>
        <label style={{ margin: "10px" }}>Drop Album Front image here:</label>
        <br />
        <input type="file" style={{ margin: "10px" }} />
        <br />
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          style={{ margin: "10px" }}
        >
          Upload
        </button>
        <br />
      </form>
      <form onSubmit={formatCoverHandler}>
        <label style={{ margin: "10px" }}>Drop Album Cover image here:</label>
        <br />
        <input type="file" style={{ margin: "10px" }} />
        <br />
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          style={{ margin: "10px" }}
        >
          Upload
        </button>
        <br />
      </form>
      <h3>
        Upload Progress {progress}% (
        {progress > 0 && progress < 100 ? (
          <h3 className="text-blue">Please wait...</h3>
        ) : null}
        )
      </h3>
      <div className="w-full max-w-xs" style={{ marginLeft: "600px" }}>
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              value={name}
              placeholder="Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={submit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Save Album
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Album Name
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Album Card Image
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Album Cover Image
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {albums.map((album) => (
                    <tr className="border-b" key={album.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {albums.indexOf(album) + 1}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {album.name}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <img
                          src={album.front_image}
                          width="10px"
                          height="10px"
                          alt=""
                        />
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <img
                          src={album.cover_image}
                          width="30px"
                          height="30px"
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            navigate({
                              pathname: "/admin/story",
                              search: createSearchParams({
                                id: album.id,
                              }).toString(),
                            });
                          }}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          type="button"
                        >
                          Add new Story
                        </button>
                      </td>
                      <td>
                        <button onClick={()=> {deleteAlbum(album.id)}} className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Album;
