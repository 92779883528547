import React, { useState, useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from "../firebase";
//import { docRef, collection, addDoc } from "@firebase/firestore";
import "./login.css";
import Navbar from "./header";
import axios from "axios";

import { useSearchParams } from "react-router-dom";


const Story = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [progress, setProgress] = useState(0);
  const [image, setImage ] = useState("");
  const [name, setName] = useState("");
  const [music, setMusic] = useState("");
  const [time, setTime] = useState("");
  const [stories, setStories ] = useState([]);
  const data = {
    name: name,
    image: image,
    music: music,
    time: time
  }

  useEffect(() => {
    const fetchAlbums = () => {
    axios.get(`https://atfal-backend.herokuapp.com/story/${id}`).then((res) => {
      console.log("Response: ", res.data);
      if (res.data !== null) {
        setStories(res.data);
    }
    }); }
    if (stories.length === 0) 
        fetchAlbums()
  }, [stories]);

  const deleteStory = (id) => {
    axios.delete(`https://atfal-backend.herokuapp.com/story/${id}`).then((res) => {
      alert("The Story has been deleted");
    }).catch(err => {
      alert("Failed to delete :(");
    })
  }
  
  const submit = () => {
    axios.post(`https://atfal-backend.herokuapp.com/story/${id}`, data
    )
    .then(function (response) {
      console.log("Response API:", response);
      alert("Story has been added successfully!");
    })
    .catch(function (error) {
      console.log(error);
      alert("Failed to add the story");
    });
  }

  const imageFormatHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadImage(file);
  };
  const uploadImage = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/stories images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log("Download URL: ", url);
          setImage(url);
          // try {
          //   addDoc(collection(db, "photos"), {
          //     type: "image",
          //     imageURL: url,
          //   });
          //   console.log("Document written with ID: ", docRef.id);
          // } catch (e) {
          //   console.error("Error adding document: ", e);
          // }
        });
      }
    );
  };
  const musicFormatHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadMusic(file);
  };
  const uploadMusic = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/stories music/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log("Download URL: ", url);
          setMusic(url);
          // try {
          //   addDoc(collection(db, "photos"), {
          //     type: "image",
          //     imageURL: url,
          //   });
          //   console.log("Document written with ID: ", docRef.id);
          // } catch (e) {
          //   console.error("Error adding document: ", e);
          // }
        });
      }
    );
  };

  return (
    <div>
      <Navbar />
      <h1>Atfal App: Upload Stories</h1>
      <form onSubmit={imageFormatHandler}>
        <label style={{ margin: "10px" }}>Drop Image File here:</label>
        <br />
        <input type="file" style={{ margin: "10px" }} />
        <br />
        <button style={{ margin: "10px" }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Upload
        </button>
        <br />
      </form>
      <form onSubmit={musicFormatHandler}>
        <label style={{ margin: "10px" }}>Drop Music File here:</label>
        <br />
        <input type="file" style={{ margin: "10px" }} />
        <br />
        <button style={{ margin: "10px" }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Upload
        </button>
        <br />
      </form>
      <h3>Upload Progress {progress}% ({progress > 0 && progress < 100 ? <h3 className="text-blue">Please wait...</h3> : null})</h3>
      <div className="w-full max-w-xs flex justify-center items-center" style={{marginLeft: "600px"}}>
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Name
            </label>
            <input
              value={name}
              onChange={(e) => {setName(e.target.value)}}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Name"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="password"
            >
              Duration
            </label>
            <input
              required={true}
              value={time}
              onChange={(e) => { setTime(e.target.value)}}
              className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="text"
              placeholder="Story Duration"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
            onClick={submit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Save Story
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Story Name
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Story Music
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Story Image
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stories.map((story) => (
                    <tr className="border-b" key={story.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {stories.indexOf(story)+1}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {story.name}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <audio controls>
                          <source src={story.music}  type="audio/mpeg" /> 
                        </audio>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <img src={story.image}  width="100" height="100"/>
                      </td>
                      <td>
                        <button onClick={()=> {deleteStory(story.id)}} className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
