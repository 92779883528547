import React from "react";
import LandingHeader from "./landingHeader"

const Privacy = () => {
  return (
    <>
    < LandingHeader/>
      <h1 className="text-4xl">Privacy Policy</h1>
      <h4 style={{ margin: "50px" }}>
        نحن نفهم أهمية حماية خصوصية الأطفال الذين تقل أعمارهم عن 13 عامًا، ونتبع
        سياسة خصوصية صارمة تتماشى مع قانون حماية الخصوصية للأطفال على الإنترنت
        (COPPA (Children's Online Privacy Protection Act وفيما يلي نوضح تفاصيل
        هذه السياسة:
      </h4>
      <h2>1. جمع المعلومات</h2>
      <p style={{ padding: "10px"}}>
        تطبيق قصص الأطفال يقوم بجمع بعض المعلومات الضرورية لتحسين تجربة
        المستخدم، مثل اسم المستخدم، عنوان البريد الإلكتروني إذا قاموا بتقديم هذه
        المعلومات طوعاً عند التسجيل في التطبيق. كما يتم جمع معلومات تقنية أخرى
        مثل عنوان IP ، نوع المتصفح والجهاز المستخدم. لا يقوم تطبيق قصص الأطفال
        بجمع أي معلومات شخصية من الأطفال الذين تقل أعمارهم عن 13 عاماً، بدون
        موافقة والديهم أو الوصي القانوني.
      </p>
      <h2>2. استخدام المعلومات</h2>
      <p style={{ padding: "10px"}}>
        تستخدم المعلومات التي يتم جمعها من الأطفال الذين تقل أعمارهم عن 13 عاماً
        فقط لأغراض تقديم خدمات التطبيق، وتحسين تجربة المستخدم، وليس لأي غرض آخر.
      </p>
      <h2>3. مشاركة المعلومات:</h2>
      <p style={{ padding: "10px"}}>
        لا يتم مشاركة أي معلومات شخصية للأطفال الذين تقل أعمارهم عن 13 عاماً مع
        أي جهة خارجية، ويتم الإفصاح عن هذه المعلومات فقط في الحالات المنصوص
        عليها بموجب قانون حماية الخصوصية للأطفال على الإنترنت COPPA.
      </p>
      <h2>4. حماية المعلومات:</h2>
      <p style={{ padding: "10px"}}>
        نحن نتخذ كافة الإجراءات اللازمة لحماية خصوصية الأطفال الذين تقل أعمارهم
        عن 13 عاماً، ونحتفظ بالمعلومات التي يتم جمعها بشكل آمن ومحمي، ونستخدم
        أحدث التقنيات الأمنية لضمان عدم تعرض المعلومات لأي انتهاك أو إساءة
        استخدام.
      </p>
      <h2>5. موافقة الوالدين:</h2>
      <p style={{ padding: "10px"}}>
        نحن نتطلع إلى حصول موافقة الوالدين أو الممثل القانوني قبل جمع أي معلومات
        عن الأطفال الذين تقل أعمارهم عن 13 عامًا، ونحن نتبع الإجراءات اللازمة
        للتحقق من صحة هذه الموافقة
      </p>
      <h2>6. حقوق الخصوصية:</h2>
      <p style={{ padding: "10px"}}>
        نحن نحترم حقوق خصوصية المستخدمين ونسعى لتزويدهم بجميع الأدوات والمعلومات
        اللازمة لمراقبة وإدارة بياناتهم الشخصية، كما يحق لهم في أي وقت طلب حذف
        معلوماتهم الشخصية من قاعدة بياناتنا
      </p>
    </>
  );
};

export default Privacy;
