
import "./App.css";
import Login from "./components/login";
import { Routes, Route } from "react-router-dom";
import Story from "./components/story";
import Album from "./components/album";
import Landing from "./components/landing";
import Privacy from "./components/privacy";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} exact></Route>
        <Route path="/admin" element={<Login />}></Route>
        <Route path="/admin/album" element={<Album />}></Route>
        <Route path="/admin/story" element={<Story />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>
        <Route component={Error} />
      </Routes>
    </div>
  );
}

export default App;
