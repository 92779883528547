import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import LandingHeader from "./landingHeader";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleNameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const login = () => {
    console.log("User Name: " + username + " Password: " + password);
    if (username === "info@atfal.app" && password === "Atfal2023") {
      navigate("/admin/album");
    } else {
      alert("Invalid username or password");
    }
  };

  return (
    <>
      <LandingHeader />
      <h2>Admin</h2>
      <h2>Login</h2>
      <h3>Please enter your credentials to login to the dashboard.</h3>
      <br />
      <div className="login">
        <form id="login">
          <label>
            <b>User Name</b>
          </label>
          <input
            type="text"
            value={username}
            onChange={handleNameChange}
            name="Uname"
            id="Uname"
            placeholder="Username"
          />
          az
          <br />
          <br />
          <label>
            <b>Password</b>
          </label>
          <br />
          <input
            type="Password"
            value={password}
            onChange={handlePasswordChange}
            name="Pass"
            id="Pass"
            placeholder="Password"
          />
          <br />
          <br />
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={login}>
            Login Here
          </button>
          <br />
          <br />
        </form>
      </div>{" "}
    </>
  );
};

export default Login;
